/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

import '../users.css'
import { commonNotification } from '../../../utils/common-notifications'

import { useGetApiInterceptor } from '../../../hooks/useGetApiInterceptor'
import { baseEndpoints } from '../../../constants'
import { axiosPatchRequest, axiosPostRequest } from '../../../services/http/axiosCalls'

import { LoaderComponent, CommonInput, SearchableDropdownComponent, CommonButtonComponent, ErrorComponent, MultiselectDropdownComponent, CommonHeadingComponent } from '../../../components'
import { isEmail } from 'react-multi-email'// for checking if valid email

import { updateClientMode, resetClientMode } from '../../../redux/utilitybarSlice'
import { setBlockingLoaderState } from '../../../redux/blockingLoader';
import { isValidateEmail } from '../../../utils/functions'

// User Edit/Create Component
const UserEdit = (props) => {
  const [isMounted, setIsMounted] = useState(false)
  const [isMountedAdvertiserAccess, setIsMountedAdvertiserAccess] = useState(false)
  const [url, setUrl] = useState('')
  const params = null
  const [advParams, setAdvParams] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch() // dispatch to enable/disable advertiser drop-down

  const client = useSelector((state) => state.client) // listener to client change in global header
  const user = useSelector((state) => state.user) // listener to client change in global header

   // State to handle User Name in form input
  const [firstName, setFirstName] = useState(''),
    [validFirstName, setValidFirstName] = useState({
      valid: true,
      errorMsg: '',
    });
  const [lastName, setLastName] = useState(''),
    [validLastName, setValidLastName] = useState({
      valid: true,
      errorMsg: '',
    }); 
  const [userEmail, setUserEmail] = useState(''),
    [validEmail, setValidEmail] = useState({
      valid: true,
      errorMsg: ''
    });
  const [disableSave, setDisableSave] = useState(true) //

  const statusList = [{ status: 'Inactive' }, { status: 'Active' }] // Status list to populate status dropdown
  const [userStatus, setUserStatus] = useState(statusList[1]) // State to handle Status in form dropdown
  const [userSSO, setUserSSO] = useState(false) // State to handle User Type in form dropdown

  const roleList = [{ role: 'Admin' }, { role: 'Manager' }, { role: 'Reporting' }] // Status list to populate status dropdown
  const [userRole, setUserRole] = useState(null) // State to handle Status in form dropdown
  const currentRole = useRef('');

  const [inCreateUser, setInCreateUser] = useState(false) // State to define, if in Create or Edit User
  const [editInitialized, setEditInitialized] = useState(false) // State to handle Edit User initialization

  const [noInProgress, setNoInProgress] = useState(true) // State to check if API is in-progress and avoid duplicate calls

  const [properLocalDateString, setProperLocalDateString] = useState();
  const [userInvitationExpired, setUserInvitationExpired] = useState(false);

  const { data, isLoading, isError, status } = useGetApiInterceptor(isMounted, params, url) // States for User Details

  const advertisersAccess = useGetApiInterceptor(isMountedAdvertiserAccess, advParams, baseEndpoints.advertisers) // States for User Categories

  const [selectedAdvertiser, setSelectedAdvertiser] = useState(
    advertisersAccess.data.table_data || []
  ),
    fullAdvertisersAccessList = useRef([]),
    [isSelectedAdvertiser, setIsSelectedAdvertiser] = useState({
      valid: true,
      errorMsg: ''
    });

  const [isAllChecked , setIsAllChecked] = useState(false);
  const [isFirstInitiation, setIsFirstInitiation] = useState(true);
  const [isAdvertiserChanged , setIsAdvertiserChanged] = useState(false);

  const [disableAllAdvertiserRows, setDisableAllAdvertiserRows] = useState(false);
  const disableAllAdvertiserRowsRef = useRef(false);
  const [advertiserList, setAdvertiserList] = useState([]);
  const originalUserSSO = useRef(false);

  useEffect(() => {
    if (advertisersAccess?.data?.table_data) {
      setAdvertiserList(advertisersAccess.data.table_data);
    }
  }, [advertisersAccess?.data?.table_data])

  useEffect(() => {
    props.getUserName(firstName);       
  }, [firstName]);

  useEffect(() => {
    if (!isFirstInitiation) {
      setDisableSave(
        validateEnableSave(
          firstName,
          lastName,
          userEmail,
          userStatus,
          userRole,
          userSSO,
        )
      );
    }
  }, [
    firstName,
    lastName,
    userEmail, 
    userStatus,
    userRole,
    fullAdvertisersAccessList.current,
    userSSO,
  ]);

  useEffect(() => {
    if (currentRole.current?.role?.toLowerCase() === 'super admin' &&  userRole?.role?.toLowerCase() !== 'super admin') {
      let selectedAdvResponse = advertisersAccess.data.table_data.filter(item => data.advertisers.includes(item.id))
      setDropdownData(selectedAdvResponse, disableAllAdvertiserRows, true);
      setAdvertiserList([...advertisersAccess.data.table_data]);
    } else if (currentRole.current?.role?.toLowerCase() !== 'super admin' &&  userRole?.role?.toLowerCase() === 'super admin' && data?.role?.toLowerCase() === 'super admin') {
      setDropdownData(advertisersAccess.data.table_data, disableAllAdvertiserRows, true);
      setAdvertiserList([...advertisersAccess.data.table_data]);
    }
    setDisableAllAdvertiserRows(userRole?.role === 'Super Admin' || (user.role === 'admin' && userEmail === user.email));
    disableAllAdvertiserRowsRef.current = userRole?.role === 'Super Admin' || (user.role === 'admin' && userEmail === user.email);
    currentRole.current = userRole;
  }, [userRole]);

  const validateEnableSave = (
     firstName,
     lastName,
     userEmail,
     userStatus,
     userRole,
     userSSO
  ) => {
      const oldStatus = data.statusLabel;
      const userRoleIndex = roleList.findIndex(i => i.role.toLowerCase() === data.role)
      const oldRole = roleList[userRoleIndex];

      const selectedOldAdvertisers = data.advertisers;

      const sortedOldAdvertisersIds = selectedOldAdvertisers?.sort();
      const sortedNewAdvertisersIds = fullAdvertisersAccessList.current.sort();

      const ssoManagedBoolean = data.sso_managed ? true : false;
      
      let isMatchedAdvertisers;
      if(!isAdvertiserChanged || data.role.toLowerCase() === 'super admin'){
        isMatchedAdvertisers = true;
      } else {
        isMatchedAdvertisers = JSON.stringify(sortedNewAdvertisersIds) === JSON.stringify(sortedOldAdvertisersIds)
      }

      const disableSaveBtn =  firstName === data.firstName &&
                    lastName === data.lastName &&
                    userEmail === data.email &&
                    userStatus?.status === oldStatus && 
                    userRole?.role === oldRole?.role &&
                    isMatchedAdvertisers &&
                    userSSO === ssoManagedBoolean;
  
      return disableSaveBtn;
  };

  const setDropdownData = (items, resettingSuperAdmin = false) => {
    const isSelectedAdvertisersChanged = items?.map(item => item.id)?.sort().join(',') !== selectedAdvertiser?.map(item => item.id)?.sort().join(',');
    if (isSelectedAdvertisersChanged && (!disableAllAdvertiserRowsRef.current || resettingSuperAdmin)) {
      setIsAdvertiserChanged(true);
    }
    if (!disableAllAdvertiserRowsRef.current || resettingSuperAdmin) {
      let newFullAdvertisersAccessList = [];
      if (!inCreateUser) {
        const nonFilteredPartOfAdvertisers = fullAdvertisersAccessList.current.filter(i => !advertisersAccess.data.table_data.map(item => item.id).includes(i));
        newFullAdvertisersAccessList = [...nonFilteredPartOfAdvertisers, ...(items?.map(item => item.id) ?? [])];
      } else {
        newFullAdvertisersAccessList = items?.map(item => item.id) ?? [];
      }
      fullAdvertisersAccessList.current = resettingSuperAdmin ? data.advertisers : newFullAdvertisersAccessList;
      setIsAllChecked(newFullAdvertisersAccessList.length === advertisersAccess?.data?.table_data?.length);
      setSelectedAdvertiser(items);
      if(!items?.length && newFullAdvertisersAccessList.length === 0){
        setIsSelectedAdvertiser({
          valid: false,
          errorMsg: 'Please select an Advertiser'
        })
      }else{
        setIsSelectedAdvertiser({
          valid: true,
          errorMsg: ''
        })
      }
    }
  }

  const checkValid = (val, key) =>{
    switch (key) {
      case 'firstName':
        if (!val?.length) {
          setValidFirstName({
            valid: false,
            errorMsg: 'First Name is required'
          });
          return;
        }
        if (val?.length > 40) {
          setValidFirstName({
            valid: false,
            errorMsg: 'Maximum allowed characters for First Name is 40'
          });
          return;
        }
        setValidFirstName({
          valid: true,
          errorMsg: ''
        });
        break;
      case 'lastName':
        if (!val?.length) {
          setValidLastName({
            valid: false,
            errorMsg: 'Last Name is required'
          });
          return;
        }
        if (val?.length > 40) {
          setValidLastName({
            valid: false,
            errorMsg: 'Maximum allowed characters for Last Name is 40'
          });
          return;
        }
        setValidLastName({
          valid: true,
          errorMsg: ''
        });
        break;
      case 'email':
        if (!val.length) {
          setValidEmail({ valid: false, errorMsg: 'E-mail is required'})
          return;
        }
        if (val.length > 50) {
          setValidEmail({ valid: false, errorMsg: 'Maximum allowed characters for E-mail is 50'})
          return;
        }
        setValidEmail({
          valid: isValidateEmail(val),
          errorMsg: isValidateEmail(val) ? '' : 'Please enter a valid E-mail',
        });
        break;
      default:
        break;
    }
  }

  const checkDisable = () =>{
    return !validFirstName.valid || !validLastName.valid || !validEmail.valid || !isSelectedAdvertiser.valid
  }

  // User Form for Create/Edit User
  const userForm = () => {
    // Condition to initialize User Form, if in Edit mode
    if (!inCreateUser && !editInitialized && data && advertisersAccess.data && advertisersAccess.data.table_data) {
      setEditInitialized(true)

      setUserInvitationExpired(false);
      if (data.cognitoInviteStatus === 'Expired') {
        setUserInvitationExpired(true);
      }
      setProperLocalDateString(data.cognitoDate);

      setFirstName(data.firstName)
      setLastName(data.lastName)
      setUserEmail(data.email)
      setUserSSO(!data.sso_managed ? false : true);
      originalUserSSO.current = data.sso_managed ? true : false;

      const statusIndex = data.enabled
      setUserStatus(statusList[statusIndex === 3 ? 1 : statusIndex])

      const userRoleIndex = roleList.findIndex(i => i.role.toLowerCase() === data.role)
      setUserRole(roleList[userRoleIndex])

      let selectedAdvResponse = advertisersAccess.data.table_data.filter(item => data.advertisers.includes(item.id))
      fullAdvertisersAccessList.current = data.advertisers;

      if(data.role.toLowerCase() === 'super admin') {
        roleList.push({ role: 'Super Admin' });

        const userRoleIndex = roleList.findIndex(i => i.role.toLowerCase() === "super admin")
        setUserRole(roleList[userRoleIndex]);
        currentRole.current = roleList[userRoleIndex];

        selectedAdvResponse = [...advertisersAccess.data.table_data]
        setIsAllChecked(true);
      }
      
      setDisableAllAdvertiserRows(currentRole.current?.role === 'Super Admin' || (user.role === 'admin' && userEmail === user.email))
      setSelectedAdvertiser(selectedAdvResponse);
      setIsAllChecked(selectedAdvResponse.length === advertisersAccess.data.table_data.length);
      setIsFirstInitiation(false);
    }

   
    const saveEnableCheck =
      (!inCreateUser && disableSave) ||
      !firstName ||
      !lastName ||
      !userEmail ||
      !userStatus ||
      !noInProgress ||
      !userRole ||
      (userRole.role.toLowerCase() !== 'super admin' && (!fullAdvertisersAccessList.current ||
      fullAdvertisersAccessList.current == 0));

    const pushRole = () => {
      roleList.unshift({ role: 'Super Admin' });
      return roleList;
    };


    return (
      <>
        <Row>
          <Col
            lg={6}
            md={12}
            style={{ padding: '20px' }}
            className='dataFieldRelative'
          >
            <CommonInput
              placeholder={'First Name'}
              inputValue={firstName}
              setInputValue={(val) => {
                setFirstName(val);
                if (val.length > 40) {
                  setValidFirstName({
                    valid: false,
                    errorMsg: 'Maximum allowed characters for First Name is 40',
                  });
                } else {
                  setValidFirstName({ valid: true, errorMsg: '' });
                }
              }}
              disabled={false}
              className={
                validFirstName.valid ? 'userInput' : 'userInput inputError'
              }
              label={'First Name '}
              labelClass={'requiredInput'}
              onBlurHandle={(val) => {
                checkValid(val.target.value, 'firstName');
              }}
            />
            {!validFirstName?.valid && (
              <span className='error'>{validFirstName?.errorMsg}</span>
            )}
          </Col>
          <Col
            lg={6}
            md={12}
            style={{ padding: '20px' }}
            className='dataFieldRelative'
          >
            <CommonInput
              placeholder={'Last Name'}
              inputValue={lastName}
              setInputValue={(val) => {
                setLastName(val);
                if (val.length > 40) {
                  setValidLastName({
                    valid: false,
                    errorMsg: 'Maximum allowed characters for Last Name is 40',
                  });
                } else {
                  setValidLastName({ valid: true, errorMsg: '' });
                }
              }}
              disabled={false}
              className={
                validLastName.valid ? 'userInput' : 'userInput inputError'
              }
              label={'Last Name '}
              labelClass={'requiredInput'}
              onBlurHandle={(val) => {
                checkValid(val.target.value, 'lastName');
              }}
            />
            {!validLastName?.valid && (
              <span className='error'>{validLastName?.errorMsg}</span>
            )}
          </Col>
          <Col
            lg={6}
            md={12}
            style={{ padding: '20px' }}
            className='dataFieldRelative'
          >
            <CommonInput
              placeholder={'Email'}
              inputValue={userEmail}
              setInputValue={(val) => {
                setUserEmail(val);
                if (val.length > 50) {
                  setValidEmail({
                    valid: false,
                    errorMsg: 'Maximum allowed characters for E-mail is 50',
                  });
                } else {
                  setValidEmail({ valid: true, errorMsg: '' });
                }
              }}
              disabled={!inCreateUser}
              className={
                validEmail.valid ? 'userInput' : 'userInput inputError'
              }
              label={'Email '}
              labelClass={'requiredInput'}
              onBlurHandle={(val) => {
                checkValid(val.target.value, 'email');
              }}
              uniqueIdentifier={'userDetailsEmailInputField'}
            />
            {!validEmail?.valid && (
              <span className='error'>{validEmail?.errorMsg}</span>
            )}
          </Col>
          <Col
            lg={6}
            md={12}
            style={{ padding: '20px' }}
            className='dataFieldRelative'
          >
            <MultiselectDropdownComponent
              dropdownId='advertisers'
              isLoading={advertisersAccess.isLoading}
              dropdownDataList={advertiserList ?? []}
              dataId='id'
              dataName='name'
              label='Advertisers Access'
              dropdownData={selectedAdvertiser}
              setDropdownData={(items) => {
                setDropdownData(items);
              }}
              placeholder='Select Advertiser'
              allSelectedPlaceHolder={
                advertisersAccess?.data?.table_data?.length
                  ? 'All Selected'
                  : 'Select Advertiser'
              }
              isAllCheckedInitially={isAllChecked}
              wrapperClass={
                isSelectedAdvertiser.valid
                  ? 'requiredDD'
                  : 'requireDD multiselectError'
              }
              alignType='right'
              ifCanBeEmptied={true}
              disableAllRows={disableAllAdvertiserRows}
            />
            {!isSelectedAdvertiser?.valid && (
              <span className='error'>{isSelectedAdvertiser?.errorMsg}</span>
            )}
          </Col>
          <Col lg={6} md={12} style={{ padding: '20px' }}>
            <SearchableDropdownComponent
              options={
                data?.role?.toLowerCase() === 'super admin'
                  ? pushRole()
                  : roleList
              }
              label='role'
              id='role'
              dropdownId='roleDD'
              dropdownLabel='Role'
              placeholderLabel={'User Role'}
              selectedValue={userRole}
              handleChange={(val) => {
                setUserRole(val);
              }}
              isLoading={false}
              isDisabled={false}
              wrapperClass={'requiredDD'}
              isSearchable={false}
            />
          </Col>
          <Col lg={6} md={12} style={{ padding: '20px' }}>
            <div className='userDetailsStatusUserSSO'>
              <SearchableDropdownComponent
                options={statusList}
                label='status'
                id='status'
                dropdownId='statusDD'
                dropdownLabel='Status'
                placeholderLabel={'User Status'}
                selectedValue={userStatus}
                handleChange={(val) => {
                  setUserStatus(val);
                }}
                isLoading={false}
                isDisabled={false}
                wrapperClass={'requiredDD'}
                isSearchable={false}
              />
              <div className='userSSOWrapper'>
                <input
                  type='checkbox'
                  id={'userSSO'}
                  className={'userSSO'}
                  checked={userSSO}
                  disabled={false}
                  onChange={(e) => {
                    setUserSSO(e.target.checked);
                  }}
                />
                <label className="userSSOLabel" htmlFor={'userSSO'}>SSO User</label>
                <div className="userSSORequired">
                  *
                </div>
              </div>
            </div>
            
          </Col>
        </Row>
        <Row>
          {properLocalDateString && !originalUserSSO.current && (
            <div className='userDetailsInvitationIndicatorDiv'>
              <b>{data?.cognitoInviteStatus}:</b>
              {properLocalDateString}
            </div>
          )}
        </Row>
        <Row>
          <Col
            className='partnerDetailstButtonWrapper'
            style={properLocalDateString && { marginTop: '10px' }}
          >
            {userInvitationExpired && properLocalDateString && !originalUserSSO.current && (
              <CommonButtonComponent
                label='Reinvite'
                isDisabled={false}
                showLoader={false}
                onClickCTA={() => {
                  resendUserInvite(userEmail, setIsMounted, dispatch);
                }}
                appendWrapperClassName='btnPrimaryWrapper'
                appendButtonClassName='btnSecondary'
              />
            )}
            <CommonButtonComponent
              label='Save'
              isDisabled={saveEnableCheck || checkDisable()}
              showLoader={false}
              onClickCTA={() => {
                saveUser();
              }}
              appendWrapperClassName='btnPrimaryWrapper'
              appendButtonClassName='btnPrimary'
            />
            <CommonButtonComponent
              label='Cancel'
              isDisabled={false}
              showLoader={false}
              onClickCTA={() => {
                navigate('/users');
              }}
              appendWrapperClassName='btnPrimaryWrapper'
              appendButtonClassName='btnSecondary'
            />
          </Col>
        </Row>
      </>
    );
  }

  // Method to Save User, for Create and Edit User
  const saveUser = () => {
    if (!isEmail(userEmail)) {
      commonNotification({ msg: 'Invalid Email Address', type: 'danger' })
      return false
    }

    if (typeof userSSO !== 'boolean') {
      commonNotification({ msg: 'Invalid SSO User', type: 'danger' })
      return false
    }

    const statusCode = statusList.findIndex((item) => item.status === userStatus?.status)

    if (userRole.role.toLowerCase() != 'super admin' && !fullAdvertisersAccessList.current?.length) {
      commonNotification({ msg: 'Advertisers Access should not be blank', type: 'danger' })
      return false
    }

    const advertisersIds = fullAdvertisersAccessList.current;
    const payload = {
      userId: client.selectedClient,
      email: userEmail.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      role: userRole.role,
      status: statusCode,
      advertisers: advertisersIds,
      uid: data.uid ?? '',
      userSSO,
      originalUserSSO: originalUserSSO.current,
    }

    let requestPromise
    if (inCreateUser) {
      requestPromise = axiosPostRequest(`${baseEndpoints.users}/create`, payload)
    } else {
      requestPromise = axiosPatchRequest(`${baseEndpoints.users}/${props.userId}`, null, payload)
    }
    dispatch(setBlockingLoaderState({active: true}));
    setNoInProgress(false)

    requestPromise.then(
      (response) => {
        if (response.status === 200) {
          commonNotification({ msg: response.message, type: 'success' })
          setTimeout(() => {
            navigate('/users')
            dispatch(setBlockingLoaderState({active: false}));
          }, 2000)
        } else if (response.status === 409 && response.error_type === 'Invalid Email' ) {
          commonNotification({
            msg: 'This E-mail already exists. Please use another E-mail',
            type: 'danger'
          })
          setValidEmail({valid: false, errorMsg: 'This E-mail already exists. Please use another E-mail'})
          const searchElem = document.getElementById(
            'userDetailsEmailInputField'
          );
          searchElem?.focus();
          setNoInProgress(true)
          dispatch(setBlockingLoaderState({active: false}));
        } else {
          commonNotification({ msg: response.error || 'Something went wrong', type: 'danger' })
          setNoInProgress(true)
          dispatch(setBlockingLoaderState({active: false}));
        }
      },
      (err) => {
        console.error(err)
        if(err?.response?.status === 409){
          commonNotification({
            msg: 'This E-mail already exists.',
            type: 'danger',
          });
          setValidEmail({valid: false, errorMsg: 'This E-mail already exists. Please use another E-mail'})
          const searchElem = document.getElementById(
            'userDetailsEmailInputField'
          );
          searchElem?.focus();
          setNoInProgress(true)
          dispatch(setBlockingLoaderState({ active: false }));
          return;
        }
        commonNotification({ msg: err?.response?.data?.error, type: 'danger' })
        setNoInProgress(true)
        dispatch(setBlockingLoaderState({active: false}));
      }
    )
  }

  // Error HTML for navigating user to Users List or Create User


  /**
   * First time to initialize api calls and set up data
   * @dependencies : client, page-number, sort-data, per-page-data
   */
  useEffect(() => {
    // Disable client dropdown from app state
    
    setAdvParams({ userId: client.selectedClient, pageSize: -1, allAdvertisers: true })
    if (props.userId === 'new') {
      props.setHeading('Create User')
      setInCreateUser(true)
    } else {
      props.setHeading('Edit User')
      dispatch(updateClientMode(true)) // Disable client dropdown
      setUrl(`${baseEndpoints.users}/details?id=${props.userId}`)
      setIsMounted(true)
    }

    setIsMountedAdvertiserAccess(true)

    return () => {
      dispatch(resetClientMode()) // Enable client dropdown
    }
  }, [props.userId, client])

  // Additional UseEffect to handle use-case, when advertiser access data changes
  useEffect(() => {
    if (advertisersAccess.data.table_data && data.advertisers) {
      let selectedAdvResponse = advertisersAccess.data.table_data.filter(item => data.advertisers.includes(item.id))
      fullAdvertisersAccessList.current = data.advertisers;

      if((selectedAdvResponse.length === 0 && data.role.toLowerCase() === 'super admin') || selectedAdvResponse.length === advertisersAccess.data.table_data.length
        || userRole?.role === 'Super Admin'){
        selectedAdvResponse = [...advertisersAccess.data.table_data]
      }

      setSelectedAdvertiser(selectedAdvResponse)
    }
  }, [advertisersAccess.data])

  useEffect(() => {
    if (!isLoading) {
      setIsMounted(false);
      setEditInitialized(false);
    }
  }, [data])
 
  const renderLoading = () =>{
    return  isLoading||advertisersAccess.isLoading ? <div className='pageLoaderWrapper'> <LoaderComponent /> </div> : renderError()
   }
 
   const renderError = () =>{
     return isError? <ErrorComponent refreshAction={setUrl} status={status} ifNotAvailable expiredMessage={<ErrorHTML />} /> : userForm()
   }

  return (
    <div className='userDetailsWrapper'>
      <CommonHeadingComponent headingLabel={inCreateUser?'Create User': 'Edit User'}/>
      <Container>
            {inCreateUser
              ? (userForm())
              : renderLoading()
              }
      </Container>
    </div>
  )
}
export default UserEdit

const ErrorHTML = () => {
  return (
    <div>
      <h3>The user does not exist!</h3>
      <div className='errorMessage'>
        Please navigate to <Link to={'/users'}>Users List</Link> or <Link to={'/users/new'}>Create New User</Link>
      </div>
    </div>
  )
}

export const resendUserInvite = async (userEmail, setIsMounted, dispatch) => {
  try {
    const response = await axiosPostRequest(
      `${baseEndpoints.users}/resend-invite`,
      { email: userEmail }
    );
    dispatch(setBlockingLoaderState({ active: true }));
    if (response.status === 200) {
      commonNotification({ msg: response.message, type: 'success' });
      setTimeout(() => {
        setIsMounted(true);
        dispatch(setBlockingLoaderState({ active: false }));
      }, [1000])
      return true;
    }
  } catch (e) {
    console.error(e);
    commonNotification({ msg: 'Something Went Wrong', type: 'danger' });
    return false;
  }
};